import { useState } from 'react'
import { removeIdToken } from '@utils/UserInfo'
import { ThemeSwitcher } from '@components/Layout/ThemeSwitcher'

export function Footer({ userInfo }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = (event) => {
    event.preventDefault()
    setIsLoading(true)
    removeIdToken()
    setTimeout(function () {
      window.location.reload()
    }, 666)
  }

  return (
    <div className="grid grid-cols-3 gap-4 mb-6">
      <div className="text-left">
        <ThemeSwitcher userInfo={userInfo} />
      </div>

      <div className="text-center">
        <small>2022 - {new Date().getFullYear()} © sealware</small>
      </div>

      <div className="text-right">
        {userInfo.isAuthenticated && (
          <>
            {!isLoading && (
              <a className="btn btn-secondary btn-sm" onClick={handleLogout}>
                Logout ({userInfo.identifier})
              </a>
            )}

            {isLoading && (
              <a className="btn btn-secondary btn-sm" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Logout...
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}
