import React from 'react'
import { setCookieValue } from '@utils/CookieStorage'

export function ThemeSwitcher({ userInfo }) {
  const currentTheme = userInfo.theme
  const cookieLifetime = 60 * 60 * 24 * 7 // 7 day(s)

  const themes = {
    sealware: 'Sealware®',
    black: 'Schwarz',
    cmyk: 'CMYK',
    synthwave: 'Synthwave',
    retro: 'Retro',
    cyberpunk: 'Cyberpunk',
    forest: 'Natur',
    coffee: 'Kaffee',
    luxury: 'Luxus',
  }

  const changeTheme = async (theme) => {
    setCookieValue('theme', theme, cookieLifetime)
    setTimeout(function () {
      window.location.reload()
    }, 666)
  }

  return (
    <>
      <a
        className="btn btn-sm"
        title="Theme ändern"
        onClick={() =>
          document.getElementById('theme-switcher-dialog').showModal()
        }
      >
        <i className="fa-solid fa-palette" />
      </a>

      <dialog id="theme-switcher-dialog" className="modal">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle absolute right-2 top-2">
              <i className="fa-solid fa-xmark" />
            </button>
          </form>
          <h3 className="font-bold text-lg">Theme ändern</h3>
          <div className="grid grid-cols-3 gap-4 py-4">
            {Object.entries(themes).map(([theme, name]) => {
              return (
                <React.Fragment key={'theme/' + theme}>
                  {currentTheme == theme ? (
                    <a className="btn btn-sm btn-active" disabled>
                      {name}
                    </a>
                  ) : (
                    <a
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        changeTheme(theme)
                      }}
                    >
                      {name}
                    </a>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </dialog>
    </>
  )
}
